<template>
	<div class="sidebar sidebar-dark sidebar-main sidebar-expand-xs" :class="{ 'sidebar-fullscreen': sidebarFullscreen }">
		<div class="sidebar-mobile-toggler text-center">
			<a href="#" class="sidebar-mobile-main-toggle" @click="closeSidebar">
				<i class="icon icon-chevron-left"></i>
			</a>
			Главное меню
			<a href="#" class="sidebar-mobile-expand" @click="onChangeSidebarFullscreen">
				<i class="icon icon-maximize"></i>
				<i class="icon icon-minimize"></i>
			</a>
		</div>

		<div class="sidebar-content">
			<div class="card card-sidebar-mobile">
				<ItemsSidebar :items="items" ref="sidebar" />
			</div>
		</div>
	</div>
</template>

<script>
import { computed } from "vue";

import { closeSidebar, sidebarFullscreen } from "@/core/layouts";

import stateStore from "@/core/store/index";

import ItemsSidebar from "./ItemsSidebar";

export default {
	components: {
		ItemsSidebar
	},

	setup() {
		const { state } = stateStore;

		return {
			items: computed(() => state.menu.items),
			sidebarFullscreen,
			closeSidebar,
			onChangeSidebarFullscreen: () => sidebarFullscreen.value = !sidebarFullscreen.value
		}
	}
};
</script>

<style></style>
