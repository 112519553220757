import { defineAsyncComponent } from 'vue';

export default {
	forms: {
		select: {
			panel: {
				width: '70%',
				height: '500px'
			},
			config: {
				fields: {
					name: {},
					items: {
						config: {
							hide: true
						}
					}
				}
			}
		}
	}
}